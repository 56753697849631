import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"
import Share from "../components/Share"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.main`
  padding: 220px 60px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 60px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  max-width: 1340px;
  margin: 0 auto;

  & > a {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  @media (max-width: 1100px) {
    padding: 220px 40px;
  }

  @media (max-width: 850px) {
    padding: 100px 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;

    grid-gap: 20px;
  }

  @media (max-width: 650px) {
    padding: 100px 30px;
  }

  @media (max-width: 375px) {
    padding: 100px 20px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & > ${BackgroundImage}:nth-child(2) {
      -ms-grid-column: 2;
    }
    & > ${BackgroundImage}:nth-child(3) {
      -ms-grid-column: 3;
    }
    & > ${BackgroundImage}:nth-child(4) {
      -ms-grid-column: 4;
    }
    & > ${BackgroundImage}:nth-child(5) {
      -ms-grid-column: 5;
    }
    & > ${BackgroundImage}:nth-child(6) {
      -ms-grid-column: 6;
    }
    & > ${BackgroundImage}:nth-child(7) {
      -ms-grid-column: 7;
    }
    & > ${BackgroundImage}:nth-child(8) {
      -ms-grid-column: 8;
    }
    & > ${BackgroundImage}:nth-child(9) {
      -ms-grid-column: 9;
    }
    & > ${BackgroundImage}:nth-child(10) {
      -ms-grid-column: 10;
    }
    & > ${BackgroundImage}:nth-child(11) {
      -ms-grid-column: 11;
    }
    & > ${BackgroundImage}:nth-child(12) {
      -ms-grid-column: 12;
    }
  }
`

const BlogCard = styled.header`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  & > div {
    position: absolute;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    padding: 2rem 1rem;
    background: rgba(255, 255, 255, 0.9);
  }

  h3 {
    font-weight: 500;
    font-size: 1.1rem;
    color: #9c997b;
    margin: 0;
    margin-bottom: 0.4rem;
  }
  h4 {
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0;
    margin-bottom: 0.2rem;
    color: #5c5c5f;
  }

  @media (max-width: 850px) {
    & > div {
      height: 80px;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 14px;
    }
  }

  @media (max-width: 375px) {
    & > div {
      padding: 10px;
    }
    h3 {
      font-size: 15px;
    }
    h4 {
      font-size: 12px;
    }
  }
`

const SocialContainer = styled.nav`
  position: absolute;
  width: 60px;
  height: auto;
  top: 20px;
  right: 25px;

  @media (max-width: 650px) {
    top: 10px;
    right: 10px;
    transform: scale(0.8);
  }
`

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          id
          title
          author {
            name
          }
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 750) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout olive footerException="blog">
      <SEO title="Blog" />
      <Container>
        {data.allSanityPost.nodes.map(node => (
          <BackgroundImage
            Tag="section"
            fluid={node.mainImage.asset.fluid}
            backgroundColor="#C4C4C4"
          >
            <Link to={`/blog/${node.slug.current}`}>
              <BlogCard>
                <div>
                  <h3>{node.title}</h3>
                  <h4>{node.author.name}</h4>
                </div>
              </BlogCard>
            </Link>
            <SocialContainer>
              <Share slug={node.slug.current} />
            </SocialContainer>
          </BackgroundImage>
        ))}
      </Container>
    </Layout>
  )
}

export default BlogPage
